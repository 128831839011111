import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Summary from 'content/blog/en/vercel-like-paas-summary';
export const _frontmatter = {
  "title": "Some reasons to build a GitOps powered PaaS with Kubernetes",
  "description": "Before diving into technical details, I'd like to give some context on why I've built this setup when managed solutions already exist.",
  "meta": {
    "description": "How to building a Vercel/Heroku like PaaS with Kubernetes"
  },
  "tags": ["Kubernetes", "GitOps", "DevOps", "Automation"],
  "publishedAt": "2022-02-11",
  "published": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Summary mdxType="Summary" />
    <hr></hr>
    <p>{`about commit short hash : bug with helm when 8 numbers`}</p>
    <p>{`port number should be an argument at run time`}</p>
    <p>{`instead of: copying the git repo
do: git archive (can use .gitattributes and export-ignore)`}</p>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      